<template>
    <a-modal
        :visible.sync="visible"
        @cancel="$emit('close')"
        @ok="submit"
        title="Insert/Replace Pages"
        width="600px"
    >
        <div class="modal-body">
            <a-form-model :model="form" :rules="rules" ref="form">
                <a-form-item>
                    <div v-if="form.files && form.files.length">
                        <strong
                            class="file-name-preview block dF my-2"
                            style="line-height: 1"
                            v-for="(file, fileI) in form.files"
                            :key="file.name"
                        >
                            {{ file.name }}
                            <a-icon
                                type="close-circle"
                                class="text-danger cursor-pointer"
                                @click="removeFile(fileI)"
                            />
                        </strong>
                    </div>
                    <div v-else>
                        <span
                            class="file-name-preview block dF my-2"
                            style="line-height: 1"
                        >
                            Select PDF file to Insert
                        </span>
                    </div>
                    <a-button @click="selectFiles" icon="upload"
                        >Upload</a-button
                    >
                    <p
                        class="text-danger"
                        v-if="showErrors && (!form.files || !form.files.length)"
                    >
                        <span>You must select a PDF</span>
                    </p>
                </a-form-item>
                <a-form-item label="Where to place" prop="description">
                    <a-radio-group v-model="form.where">
                        <label
                            class="block"
                            :class="
                                form.where === 'beforeFirst'
                                    ? 'text-primary'
                                    : ''
                            "
                        >
                            <a-radio
                                label="Before First Page"
                                value="beforeFirst"
                            />
                            Insert before First page
                        </label>
                        <label
                            class="block"
                            :class="
                                form.where === 'afterLast' ? 'text-primary' : ''
                            "
                        >
                            <a-radio
                                label="After Last Page"
                                value="afterLast"
                            />
                            Insert after Last page (Page {{ pages.length }})
                        </label>
                        <div>
                            <label
                                class="block"
                                :class="
                                    form.where === 'afterIndex'
                                        ? 'text-primary'
                                        : ''
                                "
                            >
                                <a-radio label="After:" value="afterIndex" />
                                Insert after Selected page:
                            </label>
                            <div
                                v-if="form.where === 'afterIndex'"
                                class="mb-2"
                            >
                                <a-select
                                    v-model="form.index"
                                    :options="pagesOptions"
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                class="block"
                                :class="
                                    form.where === 'replaceIndex'
                                        ? 'text-primary'
                                        : ''
                                "
                            >
                                <a-radio
                                    label="Replace:"
                                    value="replaceIndex"
                                />
                                Selected page from where you want to start
                                replace:
                            </label>
                            <div
                                v-if="form.where === 'replaceIndex'"
                                class="mb-2 ml-4"
                            >
                                <a-select
                                    v-model="form.index"
                                    :options="pagesOptions"
                                />
                            </div>
                        </div>
                        <div
                            v-if="form.where === 'replaceIndex'"
                            class="mb-2 ml-4"
                        >
                            <label
                                class="block"
                                :class="
                                    form.where === 'replaceIndex'
                                        ? 'text-primary'
                                        : ''
                                "
                            >
                                Enter the number of pages you want to replace:
                            </label>

                            <a-input-number
                                id="numberOfPagesToReplace"
                                v-model="numberOfPagesToReplace"
                                :min="1"
                            />
                        </div>
						<div
                            v-if="form.where === 'replaceIndex'"
                            class="mb-2 ml-4"
                        >
                            <label
                                class="block"
                                :class="
                                    form.where === 'replaceIndex'
                                        ? 'text-primary'
                                        : ''
                                "
                            >
                                Keep the current field placement in new pages:
                            </label>
							<a-switch v-model="keepPageFields" id="keepPageFields"/>
                        </div>
                    </a-radio-group>
                </a-form-item>
            </a-form-model>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        contract: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        showErrors: false,
        form: {
            index: 0,
            where: "",
            files: [],
        },
        numberOfPagesToReplace: 1,
		keepPageFields: false,
        rules: {
            name: [
                {
                    required: true,
                    message: "Name is required",
                    trigger: "blur",
                },
            ],
            description: [
                {
                    required: true,
                    message: "Description is required",
                    trigger: "blur",
                },
            ],
            type: [
                {
                    required: true,
                    message: "Type is required",
                    trigger: "blur",
                },
            ],
            value: [
                {
                    required: true,
                    message: "Value is required",
                    trigger: "blur",
                },
            ],
        },
    }),
    watch: {
        visible(val) {
            if (val) {
                this.form.files = [];
                this.form.where = "afterIndex";
                this.form.index = this.data.index;
                this.showErrors = false;
				this.keepPageFields = false;
            }
        },
    },
    computed: {
        pages() {
            return this.contract.pages || [];
        },
        pagesOptions() {
            return this.pages.map((page, pageI) => {
                return {
                    label: `Page ${pageI + 1}`,
                    value: pageI,
                };
            });
        },
        visible() {
            return this.data.show || false;
        },
    },
    methods: {
        removeFile(fileI) {
            this.form.files.splice(fileI, 1);
        },
        selectFiles() {
            this.$store.commit("MEDIA_SELECT", {
                title: "Select PDF to insert",
                type: "pdf",
                multiple: true,
                callback: (files) => {
                    if (!Array.isArray(files)) {
                        files = [files];
                    }
                    this.form.files = files;
                },
            });
        },
        uploadFile() {
            this.$refs.uploadFiles.click();
        },
        handleFiles(e) {
            this.form.files = e.target.files;
        },
        submit() {
            this.showErrors = false;
            if (!this.form.files || !this.form.files.length) {
                this.showErrors = true;
                return;
            }
            let index = this.form.index;
            if (this.form.where === "afterLast") {
                index = this.pages.length;
            } else if (this.form.where === "beforeFirst") {
                index = 0;
            } else if (this.form.where === "afterIndex") {
                index = index + 1;
            }

            let sendObj = {
                index,
                files: this.form.files.map((x) => x.url),
            };

            if (this.form.where === "replaceIndex") {
                sendObj.numberOfPagesToReplace =
                    Number(this.numberOfPagesToReplace) || 1;
				sendObj.keepPageFields = this.keepPageFields;
            }

            let url =
                this.form.where === "replaceIndex"
                    ? `/contract-templates/:instance/${this.contract.id}/replacepage`
                    : `/contract-templates/:instance/${this.contract.id}/addpage`;
            this.$store.commit("LOAD", true);
            this.$api
                .post(url, sendObj)
                .then(({ data }) => {
                    this.$store.commit("SET_PROP", {
                        where: ["templates", data.id],
                        what: data,
                    });
                    this.$message.success(
                        `Successfully ${
                            this.form.where === "replaceIndex"
                                ? "replaced"
                                : "inserted"
                        }  page(s)`
                    );
                    this.$emit("refresh");
                    this.$emit("close");
                })
                .catch((err) => {
                    console.error("err", err);
                    this.$message.error(
                        "Oops, an error occurred. Please try again or contact your administrator."
                    );
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
    },
};
</script>

<style>
</style>
